// utils
import { statusList } from 'translations/common';

export default {
  appComponent: {
    footer: {
      modal: {
        title: 'Are you sure you want to start fresh?',
        declineText: 'Close',
        submitText: 'Restart',
        text: 'This will start your progress over and erase all previous information.',
      },
      text: 'Over?',
      button: 'START OVER',
      privacyLink: 'Privacy Policy',
      contactLink: 'Contact Us',
    },
  },
  filePage: {
    contactInfo: {
      linkText: 'Forgot which email was used?',
      title: "You don't have the email used to purchase your tickets?",
      introVerbiage:
        "That's okay! With a little assistance from you, we should be able to find your information. If you need help, please",
    },
    fileClaim: {
      buttonName: 'Send Link',
      placeholder: 'Email',
    },
  },
  emailSent: {
    confirmation: 'We just emailed a confirmation link to ',
    header: 'Check Your Inbox. You Have a Login Link Waiting for You!',
    routerLink: 'Resend Login Link Email',
    wrong: 'Used the wrong email address? ',
    spam: ' and be sure to check your spam folder just in case.',
    button: 'Click here',
    request: "Didn't get the email? ",
  },
  orderSelectionPage: {
    dateFilter: {
      title: 'Date Range',
      labels: {
        from: 'Start Date',
        to: 'End Date',
      },
      buttons: {
        filter: 'Search',
        cancel: 'Cancel',
      },
    },
    header: {
      row1: '',
    },
    links: {
      newFlow: 'Request a Refund',
      help: 'Help Center',
    },
    pagination: {
      title: 'Page ',
    },
    itemTable: {
      statusColumn: {
        header: 'Status',
        status: statusList,
      },
    },
  },
  contactInfo: {
    text: 'Email:',
    button: 'Close',
    description: 'contact us',
  },
  certificateOfCoveragePage: {
    description: {
      part1: 'Thanks for choosing ',
    },
  },
};
