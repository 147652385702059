// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-template-curly-in-string */

// mocks
import { fanshieldGeneralConfig } from 'mocks/products/fanshield';

// types
import { ConfigFileTypes, ConfigStep } from 'types';

export const fanshieldSubmissionMapping = {
  customer: {
    first_name: '${GLOBAL.customerReducer.customer.first_name}',
    last_name: '${GLOBAL.customerReducer.customer.last_name}',
    email: '${GLOBAL.customerReducer.customer.email}',
    address_line_1:
      '${GLOBAL.formsReducer.forms.step1.billingAddress_address_1}',
    address_line_2:
      '${GLOBAL.formsReducer.forms.step1.billingAddress_address_2}',
    city: '${GLOBAL.formsReducer.forms.step1.billingAddress_city}',
    state: '${GLOBAL.formsReducer.forms.step1.billingAddress_state}',
    zip_code: '${GLOBAL.formsReducer.forms.step1.billingAddress_zip_code}',
    country: '${GLOBAL.formsReducer.forms.step1.billingAddress_country}',
    phone_number: '${GLOBAL.formsReducer.forms.step1.billingAddress_phone}',
  },
  event_name: '${GLOBAL.formsReducer.forms.step1.eventName}',
  event_location: '${GLOBAL.formsReducer.forms.step1.location}',
  event_decision: '${GLOBAL.formsReducer.forms.step1.event_decision}',
  event_date: '${GLOBAL.formsReducer.forms.step1.date}',
  incident_date: '${GLOBAL.formsReducer.forms.step3.incidentDate}',
  attendance_decision: '${GLOBAL.formsReducer.forms.step1.attendance_decision}',
  arrival_date: '${GLOBAL.formsReducer.forms.step1.attendance_arrival_date}',
  departure_date:
    '${GLOBAL.formsReducer.forms.step1.attendance_departure_date}',
  statement: '${GLOBAL.formsReducer.forms.step3.statement',
  files: [
    '${GLOBAL.formsReducer.forms.step1.receiptUpload}',
    '${GLOBAL.formsReducer.forms.step3.incidentUpload}',
  ],
  peril: '${GLOBAL.formsReducer.forms.step2.peril.id}',
  additional_comment: 'None Provided.',
};

export const fanshieldSteps: ConfigStep[] = [
  {
    name: 'Event Info',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Additional Information Needed',
          footer: '',
          description:
            'To begin processing your refund request, we need a little more information. Thank you.',
        },
      },
      {
        component: 'InputPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
          },
          name: 'eventName',
          required: true,
          defaultValue: '${GLOBAL.ordersReducer.selectedOrder.event.name}',
        },
        verbiages: {
          description:
            'What is the name of the event? (Must match the supporting documentation you are providing.)',
          placeholder: 'Enter Event Name',
          reviewPageTitle: 'Event Name',
        },
      },
      {
        component: 'InputPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
          },
          name: 'location',
          required: true,
          defaultValue: '${GLOBAL.ordersReducer.selectedOrder.event.location}',
        },
        verbiages: {
          description:
            'Where is the event located? (Must match the supporting documentation you are providing.)',
          placeholder: 'Enter City',
          reviewPageTitle: 'Location',
        },
      },
      {
        component: 'DatePanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 4,
          },
          name: 'date',
          required: true,
          defaultValue: '${GLOBAL.ordersReducer.selectedOrder.event.date}',
        },
        verbiages: {
          description:
            'What is the event date? (Must match the supporting documentation you are providing.)',
          reviewPageTitle: 'Event Date',
        },
      },
      {
        component: 'DecisionPanel',
        verbiages: {
          description: 'Was the event ${LOCAL.eventName} cancelled?',
          courtesyMessage:
            'Please note: When an event organizer cancels the event, it voids your protection plan per the Terms and Conditions. Please contact your event organizer for further details.',
          confirm: 'YES',
          reject: 'NO',
        },
        props: {
          displayChildrenOnYes: false,
          name: 'event_decision',
          childrenComponents: [
            {
              component: 'DecisionPanel',
              verbiages: {
                description: 'Did you attend ${LOCAL.eventName}?',
                courtesyMessage: '',
                confirm: 'YES',
                reject: 'NO',
              },
              props: {
                displayChildrenOnYes: true,
                displayCourtesyMessageOnYes: false,
                name: 'attendance_decision',
                childrenComponents: [
                  {
                    component: 'DatePanel',
                    props: {
                      reviewPageGrid: {
                        xs: 12,
                        sm: 4,
                      },
                      name: 'attendance',
                      required: true,
                      defaultValue: {
                        arrival_date: '',
                        departure_date: '',
                      },
                      displayTime: true,
                    },
                    verbiages: {
                      description:
                        'Date and time of when you attended the event',
                      reviewPageTitle: 'Attendance Date',
                    },
                  },
                ],
              },
            },
            {
              component: 'AddressPanel',
              props: {
                reviewPageGrid: {
                  xs: 12,
                  sm: 4,
                },
                name: 'billingAddress',
                required: true,
                defaultValue: {
                  address_1: '${GLOBAL.customerReducer.customer.address_1}',
                  address_2: '${GLOBAL.customerReducer.customer.address_2}',
                  city: '${GLOBAL.customerReducer.customer.city}',
                  state: '${GLOBAL.customerReducer.customer.state}',
                  zip_code: '${GLOBAL.customerReducer.customer.zip_code}',
                  country: '${GLOBAL.customerReducer.customer.country}',
                  phone: '${GLOBAL.customerReducer.customer.phone}',
                },
              },
              verbiages: {
                description: 'Billing Information associated with purchase.',
                reviewPageTitle: 'Billing Address',
              },
            },
            {
              component: 'FileUploadPanel',
              props: {
                reviewPageGrid: {
                  xs: 12,
                },
                name: 'receiptUpload',
                defaultValue: [],
                required: false,
                document_type: 'R',
              },
              verbiages: {
                description:
                  'To process your refund request, please upload your Order Purchase Receipt here. Important: we need the receipt from your original purchase, not the ticket itself.',
                contactInfoText:
                  'To help expedite your refund request, we suggest providing all supporting documentation at the time of submission. However, if you don’t have supporting documents at this time, you can continue your refund request by clicking "Next"; and provide all supporting documentation at a later time.',
                noFileUploaded: 'No file(s) uploaded',
                reviewPageTitle: 'Supporting Documentation',
                acceptance:
                  'We can only accept the following file formats: .jpg, .png, .pdf and .mp4',
              },
            },
            {
              component: 'StepSubmitButton',
              verbiages: {
                text: 'Next',
                allCompleteText: 'Update',
              },
            },
          ],
        },
      },
    ],
  },
  {
    name: 'Covered Reasons',
    backButtonText: 'Event Info',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          description:
            'Below is a list of covered reasons. Please select the one (1) scenario that prevented you from attending your event.',
          title: 'What Kept You From Attending?',
          footer: '',
        },
      },
      {
        component: 'PerilsPanel',
        props: {
          required: true,
          name: 'peril',
        },
        verbiages: {
          reviewPageTitle: 'Covered Reason',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Documents',
    backButtonText: 'Covered Reasons',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Nearly Finished!  Just Need a Few More Details.',
        },
      },
      {
        component: 'TextAreaPanel',
        props: {
          characterLimit: 2048,
          reviewPageGrid: {
            xs: 12,
            sm: 4,
            md: 5,
          },
          name: 'statement',
          required: true,
        },
        verbiages: {
          description:
            'Please include names and relationships of all ticket holders in your Personal Statement below.',
          placeholder: 'Personal Statement',
          reviewPageTitle: 'Personal Statement',
        },
      },
      {
        component: 'DatePanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 3,
            md: 2,
          },
          name: 'incidentDate',
          required: true,
        },
        verbiages: {
          description:
            'On what date did your incident occur? (Must match supporting documentation you are providing.)',
          reviewPageTitle: 'Incident Date',
        },
      },
      {
        component: 'FileUploadPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 5,
          },
          name: 'incidentUpload',
          defaultValue: [],
          required: false,
          document_type: 'SD',
        },
        verbiages: {
          description:
            'Please upload the supporting documentation for your specific refund request reason (based on the required documentation defined in the Terms and Conditions provided on the site of your purchase).',
          contactInfoText:
            'To help expedite your refund request, we suggest providing all supporting documentation at the time of submission. However, if you don’t have supporting documents at this time, you can continue your refund request by clicking "Next" and provide all supporting documentation at a later time.',
          noFileUploaded: 'No file(s) uploaded',
          reviewPageTitle: 'Supporting Documentation',
          acceptance:
            'We can only accept the following file formats: .jpg, .png, .pdf and .mp4',
          footer:
            'Typical documentation requested: ${GLOBAL.formsReducer.forms.step2.peril.documents_description} <a href="https://www.fanshield.insure/markelterms">APS PDF</a>',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Review',
    backButtonText: 'Documents',
    fields: [
      {
        component: 'ReviewPanel',
        verbiages: {},
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const fanshieldRefundConfig: ConfigFileTypes = {
  ...fanshieldGeneralConfig,
  steps: fanshieldSteps,
  submissionMapping: fanshieldSubmissionMapping,
};
