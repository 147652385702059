/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendEmailToken } from './thunks';

export type EmailState = {
  loading: boolean;
  errorMessage: string;
  successMessage: string;
  email: string;
};

const initialState: EmailState = {
  loading: false,
  errorMessage: '',
  successMessage: '',
  email: '',
};

export const EmailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    clearEmailState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(sendEmailToken.fulfilled, (state, { payload }) => {
      state.successMessage = 'The login link email has been sent successfully!';
      state.errorMessage = '';
      state.loading = false;
    });
    builder.addCase(sendEmailToken.rejected, (state, action) => {
      const error = action.payload as string;
      state.errorMessage = error;
      state.successMessage = '';
      state.loading = false;
    });
    builder.addCase(sendEmailToken.pending, (state, _) => {
      state.loading = true;
    });
  },
});

export const { setEmail, clearEmailState } = EmailSlice.actions;

export default EmailSlice.reducer;
/* eslint-enable no-param-reassign */
