import React, { ElementType, VFC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { resolveFormComponentVerbiages, useAppSelector } from 'store/hooks';
import { getEntireForm } from 'store/modules/forms';
import {
  AddressPanelType,
  CreditCardType,
  DatePanelType,
  DecisionPanelType,
  DescriptionPanelType,
  FileUploadPanelType,
  HeaderDescriptionType,
  InputPanelType,
  MultiSelectType,
  StepSubmitButtonType,
  TextAreaType,
  UpdateBillingAddressPanelType,
} from 'utils/translation/types';
import HeaderDescription from 'components/HeaderDescription';
import {
  ComponentProps,
  InputPanelConfigProps,
  DatePanelConfigProps,
  DecisionPanelConfigProps,
  AddressPanelConfigProps,
  FileUploadPanelConfigProps,
  PerilsPanelConfigProps,
  TextAreaConfigProps,
  CreditCardConfigProps,
  VerbiageTypes,
} from 'types';
import { StepComponent } from 'components/FormBuilder/types';
import { initMultiselect } from 'utils/radioGroup';
import { CertificateCoveragePage } from 'components/CertificateCoveragePage';
import ReviewPage from 'components/ReviewPage';
import ThankYouPage from 'components/ThankYouPage';
import { InputPanel } from '../InputPanel';
import { DatePanel } from '../DatePanel';
import { DecisionPanel } from '../DecisionPanel';
import { AddressPanel } from '../AddressPanel';
import { UpdateBillingAddressPanel } from '../UpdateBillingAddressPanel';
import { DescriptionPanel } from '../DescriptionPanel';
import { FileUploadPanel } from '../FileUploadPanel';
import { TextAreaPanel } from '../TextArea';
import PerilsPanel from '../PerilsPanel';
import { RadioGroupPanel } from '../RadioGroup';
import { CreditCardPanel } from '../CreditCardPanel';

const GenericFormField: VFC<{
  type: StepComponent;
  formApi: UseFormReturn<any, any>;
  props?: ComponentProps;
  setIsSubmitting: (value: boolean) => void;
  StepSubmitButton: ElementType;
  verbiages: VerbiageTypes;
}> = function ({
  type,
  formApi,
  props,
  verbiages,
  setIsSubmitting,
  StepSubmitButton,
}) {
  // redux state
  const form = useAppSelector(getEntireForm);

  switch (type) {
    case 'HeaderDescription': {
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as HeaderDescriptionType;
      return (
        <HeaderDescription
          title={translation.title}
          description={translation.description}
          footer={translation.footer}
        />
      );
    }

    case 'InputPanel': {
      const p = props as InputPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as InputPanelType;
      return (
        <InputPanel
          name={p.name}
          formApi={formApi}
          description={translation.description}
          label={translation.placeholder}
        />
      );
    }

    case 'DatePanel': {
      const p = props as DatePanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as DatePanelType;
      return (
        <DatePanel
          name={p.name}
          formApi={formApi}
          description={translation.description}
          displayTime={p.displayTime}
        />
      );
    }

    case 'DecisionPanel': {
      const p = props as DecisionPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as DecisionPanelType;

      const pathname = window.location.pathname.split('/');
      const step = pathname[pathname.length - 1];

      return (
        <DecisionPanel
          formApi={formApi}
          childrenComponents={p.childrenComponents}
          courtesyMessage={translation.courtesyMessage}
          displayChildrenOnYes={p.displayChildrenOnYes}
          description={translation.description}
          setIsSubmitting={setIsSubmitting}
          StepSubmitButton={StepSubmitButton}
          confirm={translation.confirm}
          reject={translation.reject}
          name={p.name}
          storedValue={form[step]?.[p.name]}
        />
      );
    }

    case 'AddressPanel': {
      const p = props as AddressPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as AddressPanelType;
      return (
        <AddressPanel
          name={p.name}
          formApi={formApi}
          description={translation.description}
        />
      );
    }

    case 'UpdateBillingAddressPanel': {
      const p = props as AddressPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as UpdateBillingAddressPanelType;
      return (
        <UpdateBillingAddressPanel
          name={p.name}
          formApi={formApi}
          translation={translation}
        />
      );
    }

    case 'FileUploadPanel': {
      const p = props as FileUploadPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as FileUploadPanelType;
      const pathname = window.location.pathname.split('/');
      const step = pathname[pathname.length - 1];
      return (
        <FileUploadPanel
          document_type={p.document_type}
          name={p.name}
          formApi={formApi}
          description={translation.description}
          acceptance={translation.acceptance}
          contactInfoText={translation.contactInfoText}
          footer={translation.footer}
          setIsSubmitting={setIsSubmitting}
          storedValue={form[step]?.[p.name]}
        />
      );
    }

    case 'StepSubmitButton': {
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as StepSubmitButtonType;
      return (
        <StepSubmitButton
          text={translation.text}
          allCompleteText={translation.allCompleteText}
        />
      );
    }

    case 'PerilsPanel': {
      const p = props as PerilsPanelConfigProps;
      return <PerilsPanel formApi={formApi} name={p.name} />;
    }

    case 'DescriptionPanel': {
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as DescriptionPanelType;
      return (
        <DescriptionPanel
          title={translation.title}
          description={translation.description}
        />
      );
    }

    case 'TextAreaPanel': {
      const p = props as TextAreaConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as TextAreaType;
      return (
        <TextAreaPanel
          placeholder={translation.placeholder}
          description={translation.description}
          name={p.name}
          formApi={formApi}
          characterLimit={p.characterLimit}
        />
      );
    }

    case 'MultiSelect': {
      const p = props as any;
      const v = verbiages as MultiSelectType;

      initMultiselect(v.options);
      return (
        <RadioGroupPanel name={p.name} formApi={formApi} label={v.label} />
      );
    }

    case 'CreditCardPanel': {
      const p = props as CreditCardConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as CreditCardType;
      return (
        <CreditCardPanel
          description={translation.description}
          name={p.name}
          formApi={formApi}
        />
      );
    }

    case 'ReviewPanel': {
      return <ReviewPage />;
    }

    case 'ThankYouPage': {
      return <ThankYouPage />;
    }

    case 'CertificatePage': {
      return <CertificateCoveragePage />;
    }

    default:
      // eslint-disable-next-line no-console
      console.error('Component not found', type);
      return null;
  }
};

export default GenericFormField;
