// packages
import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
  combineReducers,
} from '@reduxjs/toolkit';

// redux
import alertReducer from 'store/modules/alert/slice';
import authReducer from 'store/modules/auth/slice';
import claimsReducer from 'store/modules/claims/slice';
import configFileReducer from 'store/modules/config/slice';
import customerReducer from 'store/modules/customer/slice';
import emailReducer from 'store/modules/email/slice';
import formsReducer from 'store/modules/forms';
import ordersReducer from 'store/modules/orders/slice';
import perilsReducer from 'store/modules/perils/slice';
import thanksReducer from 'store/modules/thanks/slice';

// utils
import { isProduction } from 'utils/env';
import { loadState, saveState } from 'store/browserStorage';

// types
import type { RootState } from 'store/types';

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: combineReducers({
      alertReducer,
      authReducer,
      claimsReducer,
      configFileReducer,
      customerReducer,
      emailReducer,
      formsReducer,
      ordersReducer,
      perilsReducer,
      thanksReducer,
    }),
    devTools: !isProduction,
    preloadedState,
  });

// load persisted state from local storage, if available
export const store = setupStore(loadState());

// subscribe to global state changes and persist in local storage
store.subscribe(() => saveState(store.getState()));

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunkCreator = (...args: any[]) => AppThunk;
export type { RootState };
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type { AppDispatchedAction, AppTask } from 'store/hooks';
