// types
import type { RootState } from 'store';
import type { SelectCustomer } from 'store/modules/customer/types';
import type {
  ClaimStatusType,
  FormattedItem,
  FormattedOrder,
  ItemStatusType,
  ProductOrder,
} from 'store/modules/orders/types';

export const selectOrdersErrorMessage = (state: RootState) =>
  state.ordersReducer.items.status === 'error' &&
  state.ordersReducer.items.errorMessage;

export const selectOrdersAreLoading = (state: RootState) =>
  state.ordersReducer.items.status === 'loading';

export const selectOrdersInitiated = (state: RootState) =>
  state.ordersReducer.items.status !== 'idle';

export const selectCustomer =
  (unique_id?: string) =>
  (state: RootState): SelectCustomer | undefined => {
    if (state.ordersReducer.items.status !== 'success') return undefined;

    if (!unique_id) {
      return {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        phone: '',
      };
    }
    const data = state.ordersReducer.items.data as ProductOrder[];
    const order = data.find((order) => order.id === unique_id);

    if (!order)
      throw new Error(
        `Item with id '${unique_id}' not found, can't get the customer`
      );

    const customer: SelectCustomer = {
      id: order.customer?.id ?? '',
      email: order.customer?.email ?? '',
      first_name: order.customer?.first_name ?? '',
      last_name: order.customer?.last_name ?? '',
      address_1: order.customer?.address_1 ?? '',
      address_2: order.customer?.address_2 ?? '',
      city: order.customer?.city ?? '',
      state: order.customer?.state ?? '',
      zip_code: order.customer?.postal_code ?? '',
      country: order.customer?.country ?? '',
      phone: order.customer?.phone ?? '',
    };
    return customer;
  };

export const selectOrdersWithItemsIssued = (state: RootState) => {
  const orders = selectOrders(state);
  const ordersWithIssuedStatus = orders.filter((order) =>
    order.items.some((item) => item.status === 'issued')
  );

  return ordersWithIssuedStatus;
};

export const selectOrders = (state: RootState) => {
  if (state.ordersReducer.items.status !== 'success') return [];

  const data = state.ordersReducer.items.data as ProductOrder[];
  if (!data) return [];
  const orders: FormattedOrder[] = data.map((order) => ({
    uuid: order.id,
    client_name: order.client.name,
    client_id: order.client.id,
    created: order.created,
    order_subtotal: order.order_subtotal,
    order_subtotal_literal: order.order_subtotal_literal,
    order_premium_total: order.order_premium_total,
    order_premium_total_literal: order.order_premium_total_literal,
    reference_id: order.reference_id,
    locale: order.customer.locale,
    jurisdiction: order?.jurisdiction,
    currency: order.currency,
    customer: {
      address_1: order.customer.address_1,
      city: order.customer.city,
      state: order.customer.state,
      country: order.customer.country,
      postal_code: order.customer.postal_code,
      phone: order.customer.phone,
    },
    items: order.items.map((item) => ({
      unit_cost: item.unit_cost,
      unit_cost_literal: item.unit_cost_literal,
      premium: item.premium,
      premium_literal: item.premium_literal,
      id: item.id,
      claim_status: item.claim_status.toLowerCase() as ClaimStatusType,
      status: item.status.toLowerCase() as ItemStatusType,
      name: item.name,
      description: item.description,
      tracking_number: item.tracking?.number,
      tracking_number_issued: item.tracking?.created,
      tracking_carrier: item.tracking?.carrier,
      reference_id: item.reference_id,
      issued: item.issued,
      event: item.event ? item.event : null,
      look_period: item.look_period,
      policy: {
        event_interruption: item?.policy?.event_interruption,
        id: item.policy?.id,
        underwriter: item.policy?.underwriter,
        sold_by: item.policy?.sold_by ?? '',
        legal_signature: item.policy?.legal_signature,
        certificate_of_coverage: item.policy?.certificate_of_coverage,
        terms_and_conditions: item.policy?.terms_and_conditions,
        weather_inconvenience: item?.policy?.weather_inconvenience,
        cancel_for_any_reason: item?.policy?.cancel_for_any_reason,
        is_weather_interruption: item?.policy?.is_weather_interruption,
        is_event_interruption: item?.policy?.is_event_interruption,
      },
    })),
  }));
  return orders;
};

export const selectOrderCount = (state: RootState) =>
  state.ordersReducer.items.status !== 'success'
    ? undefined
    : state.ordersReducer.items.count;

export const selectSelectedOrder = (
  state: RootState
): FormattedOrder | undefined => {
  const data = state.ordersReducer.selectedOrder;
  if (!data) {
    return undefined;
  }

  return data;
};

export const selectSelectedItems = (state: RootState): FormattedItem[] => {
  const data = state.ordersReducer.selectedItems;

  if (!data) {
    return [];
  }

  return data;
};

export const selectIsOrderPageComplete = (state: RootState) =>
  selectSelectedItems(state).length > 0 && selectFileUploadConsent(state);

export const selectFileUploadConsent = (state: RootState) =>
  state.ordersReducer.fileUploadConsent;

export const selectEmail = (state: RootState) => state.ordersReducer.email;

export const selectCertNum = (state: RootState) => state.ordersReducer.cert_num;

export const selectUnderwriter = (state: RootState) =>
  state.ordersReducer.selectedItems.length > 0
    ? state.ordersReducer.selectedItems[0].policy?.underwriter
    : '';
export const selectSignatureLegalVerbiage = (state: RootState) =>
  state.ordersReducer.selectedItems.length > 0
    ? state.ordersReducer.selectedItems[0].policy?.legal_signature
    : '';
