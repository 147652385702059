export default {
  filePage: {
    title: 'Refund Program Terms',
    description: 'Please enter the email you used for your purchase.',
    contactInfo: {
      description: 'help@protecht.com',
      introVerbiage:
        'That’s okay! With a little assistance from you, we should be able to find your information. If you need help, please contact ',
    },
  },
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Enhanced Refund Program',
    footer: {
      title: 'Need to Start',
      disclaimer: '',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  orderSelectionPage: {
    noOrders: 'No Certificates found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      title: 'Your Refund Program Terms',
      row2: "Please select the Enhanced Refund Program ID for which you'd like to view your Refund Program Terms.",
    },
  },
  certificateOfCoveragePage: {
    description: {
      product: '',
      part2:
        ' to protect your upcoming experience. Below is information regarding your Refund Program. If you have any questions, please ',
    },
    block1: {
      cardTitle: 'Refund Program Details',
      row1: 'Refund Program',
      row2: 'Total Cost of Refund Program',
      title: 'Click here to view your full Refund Program Terms',
    },
  },
  links: {
    helpEmail: 'mailto:help@protecht.com',
  },
};
