import { Peril } from 'store/modules/perils/types';

export const mockPerils: { count: number; items: Peril[] } = {
  count: 2,
  items: [
    {
      id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
      name: 'Transportation Delay',
      description: 'Transportation Delay of Common Carrier',
      legal_description:
        'A Ticketholder not arriving at the venue due to a delay by the Common Carrier used for transportation',
      icon: 'MedicalServices',
      documents_description: 'ID, Driving Licence ',
      type: 'standard',
    },
    {
      id: '5e1630f7-8069-4d6a-ba26-576bdfdf68d1',
      name: 'Severe Weather',
      description: 'Weather Interruption of the Event',
      legal_description:
        'Severe weather interrupting the ability of the Ticketholder to attend the event due to danger',
      icon: 'Thunderstorm',
      documents_description: '',
      type: 'weather_interruption',
    },
  ],
};
