/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-template-curly-in-string */

// mocks
import { shopGGeneralConfig } from 'mocks/products/shopguarantee';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

export const shopGCancellationSteps: ConfigStep[] = [
  {
    name: 'Protection Cancellation',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Why Are You Cancelling',
          footer: 'Before you cancel your protection, let us know why?',
          description:
            'Cancelling your certificates will prevent you from protecting your purchase from unexpected circumstances. Are you sure you want to cancel your ShopGuarantee Protection?',
        },
      },
      {
        component: 'MultiSelect',
        props: {
          required: true,
          name: 'cancellation',
        },
        verbiages: {
          reviewPageTitle: 'Cancellation',
          label: 'Write your description',
          options: [
            {
              key: 'accidental_purchase',
              value: 'I purchased this protection in error.',
            },
            {
              key: 'not_purchased',
              value: 'I did not purchase this protection',
            },
            { key: 'will_attend', value: 'I no longer want the protection' },
            {
              key: 'too_expensive',
              value: 'This protection is too expensive.',
            },
            { key: 'other', value: 'Other' },
          ],
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Review',
    backButtonText: 'Protection Cancellation',
    fields: [
      {
        component: 'ReviewPanel',
        verbiages: {},
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const shopGCancellationSubmissionMapping = {
  reason: '${GLOBAL.formsReducer.forms.step1.cancellation}',
};

export const shopGCancellationConfig: ConfigFileTypes = {
  ...shopGGeneralConfig,
  steps: shopGCancellationSteps,
  submissionMapping: shopGCancellationSubmissionMapping,
};
