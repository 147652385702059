export default {
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Refunds Form',
    footer: {
      title: 'Need to Start Your Request',
      disclaimer: '',
    },
  },
  filePage: {
    title: 'Certificate Cancellation',
    contactInfo: {
      description: 'help@protecht.com',
      introVerbiage:
        'That’s okay! With a little assistance from you, we should be able to find your information. If you need help, please contact ',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you consent to the processing of your personal information under our privacy policy (available below).',
    button: 'Continue',
    noOrders: 'No refund protection found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      row2: 'Need to cancel the certificate you purchased? To receive the full refund of your purchase, you must cancel your ticket protection within the cancellation window specified in our Terms and Conditions. Below you will find all of your previous orders. Please select an order below to view the certificates that you wish to cancel.',
    },
  },
  reviewPage: {
    header: {
      description:
        'Please review the information below. If you are sure you wish to cancel, click “Confirm Cancellation”.',
    },
    sign: {
      button: 'Confirm Cancellation',
      cancel: 'Do Not Cancel',
      note: 'Please note: Once you cancel, all your information will be immediately and permanently deleted',
      claims: {
        text: "By clicking 'Submit Refund' you certify that all information on this refund request is accurate and truthful under  penalty of perjury.  FanShield, RegShield, and TourShield are marketing product names for the ticket event programme Refund Protection. Terms, Conditions,",
        footer:
          ' and Coverage may vary. See Application and Refund Protection for full details. You can view Protecht, ',
        floridaText: '',
        link: {
          text: {
            row2: 'updates concerning your Refund Protection.',
          },
        },
      },
    },
    reviewTable: {
      total: 'Protection Cost to be Refunded: ',
      title: 'Description',
      covered: 'Protected Amount',
    },
  },
  addressForm: {
    labels: {
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      description: {
        row2: 'Confirmation',
      },
      title: {
        submitted: 'Thank You, Have a Great Day!',
        notSubmitted:
          'Thank You. Your Cancellation Request Has Been Submitted.',
      },
    },
    description:
      'Please retain your confirmation number above for future reference. Please ',
  },
  links: {
    helpEmail: 'mailto:help@protecht.com',
  },
};
