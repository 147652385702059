// packages
import axios, { AxiosRequestConfig } from 'axios';

// redux
import { errorInterceptor } from 'store/errorInterceptor';
import { selectAccessToken } from 'store/modules/auth/selectors';

// utils
import { getToken } from 'utils/localstorage';

export const orderApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_ORDER_URL}/`,
});

export const claimApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_CLAIM_URL}/`,
});

export const configApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_CONFIG_URL}/`,
});

export const authApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_AUTH_URL}/auth/`,
});

const attachHeader = (config: AxiosRequestConfig<any>, getState) => {
  const token = selectAccessToken(getState()) || getToken();

  if (
    token &&
    config.headers &&
    config.url &&
    !config.url.includes('email-token')
  ) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const setupInterceptors = (store) => {
  const { dispatch, getState } = store;

  const filterErrorInterceptorByStatusCodes = (error, errorCodes: [number]) => {
    const errorStatus = error.response.status;

    if (errorCodes.includes(errorStatus)) {
      return Promise.reject(error);
    }

    return errorInterceptor(error, dispatch, getState);
  };

  orderApi.interceptors.request.use(
    (request) => attachHeader(request, getState),
    (request) => request
  );
  claimApi.interceptors.request.use(
    (request) => attachHeader(request, getState),
    (request) => request
  );
  authApi.interceptors.request.use(
    (request) => attachHeader(request, getState),
    (request) => request
  );
  orderApi.interceptors.response.use(
    (response) => response,
    (response) => filterErrorInterceptorByStatusCodes(response, [404])
  );
  claimApi.interceptors.response.use(
    (response) => response,
    (response) => errorInterceptor(response, dispatch, getState)
  );
  configApi.interceptors.response.use(
    (response) => response,
    (response) => errorInterceptor(response, dispatch, getState)
  );
  authApi.interceptors.response.use(
    (response) => response,
    (response) => errorInterceptor(response, dispatch, getState)
  );
};
