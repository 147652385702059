// mocks
import { fanshieldGeneralConfig } from 'mocks/products/fanshield';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';
/* eslint-disable no-template-curly-in-string */

export const fanshieldCancellationSteps: ConfigStep[] = [
  {
    name: 'Protection Cancellation',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Why Are You Cancelling?',
          description:
            'Cancelling your certificates will prevent you from protecting your purchase from unexpected circumstances. Are you sure you want to cancel your FanShield Protection?',
          footer: 'Before you cancel your protection, let us know why?',
        },
      },
      {
        component: 'MultiSelect',
        props: {
          required: true,
          name: 'cancellation',
        },
        verbiages: {
          reviewPageTitle: 'Cancellation',
          label: 'Write your description',
          options: [
            {
              key: 'accidental_purchase',
              value: 'I purchased this protection in error.',
            },
            {
              key: 'not_purchased',
              value: 'I did not purchase this protection.',
            },
            {
              key: 'will_attend',
              value: "I'm 100% sure I am attending the event.",
            },
            {
              key: 'too_expensive',
              value: 'This protection is too expensive.',
            },
            { key: 'other', value: 'Other' },
          ],
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Review',
    backButtonText: 'Protection Cancellation',
    fields: [
      {
        component: 'ReviewPanel',
        verbiages: {},
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const fanshieldCancellationSubmissionMapping = {
  reason: '${GLOBAL.formsReducer.forms.step1.cancellation}',
};

export const fanshieldCancellationConfig: ConfigFileTypes = {
  ...fanshieldGeneralConfig,
  steps: fanshieldCancellationSteps,
  submissionMapping: fanshieldCancellationSubmissionMapping,
};

/* eslint-enable no-template-curly-in-string */
