import React from 'react';
import Layout from 'components/Layout';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import Panel from 'components/Panel';
import { radioGroupText } from 'utils/radioGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

export const RadioGroupPanel: React.FC<{
  name: string;
  formApi: UseFormReturn<any, any>;
  label: string;
}> = function ({ formApi: { control }, name, label }) {
  return (
    <Panel>
      <Layout>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl>
              <ControlLabel name={name} value={value} onChange={onChange} />
              <Description
                value={value}
                name={name}
                control={control}
                label={label}
              />
              <FormHelperText error={Boolean(error?.message)}>
                {error?.message ?? ' '}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Layout>
    </Panel>
  );
};

const ControlLabel: React.FC<{
  name: string;
  value: any;
  onChange: (...event: any[]) => void;
}> = function ({ name, value, onChange }) {
  const radioGroupOptions = radioGroupText();
  const LabelList: JSX.Element[] = radioGroupOptions.map(({ key, value }) => (
    <FormControlLabel
      key={key}
      value={key}
      control={<Radio />}
      label={value}
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: { xs: '0.7rem', md: '1rem' },
        },
      }}
    />
  ));

  return (
    <RadioGroup
      sx={{ marginTop: 4 }}
      name={name}
      value={value}
      onChange={onChange}
    >
      {LabelList.map((value) => value)}
    </RadioGroup>
  );
};

const Description: React.FC<{
  name: string;
  control: Control<any, any>;
  value: any;
  label: string;
}> = function ({ value, name, control, label }) {
  if (value !== 'other') return null;

  return (
    <Controller
      name={`${name}_description`}
      control={control}
      render={({ field: { onChange, value, ref, ...rest } }) => (
        <TextField
          sx={{ marginTop: 3 }}
          variant="filled"
          inputRef={ref}
          {...rest}
          onChange={onChange}
          value={value ?? ''}
          label={label}
        />
      )}
    />
  );
};
