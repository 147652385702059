export default {
  appComponent: {
    title: 'Continue With Your Unfinished Request?',
    appTitle: 'Requests Form',
    footer: {
      title: 'Need to Start Your Request',
      disclaimer:
        'Protecht is responsible for administering the refund program provided by the seller to you.',
    },
    header: {
      file: {
        title: 'REQUEST A REFUND',
      },
    },
  },
  filePage: {
    title: 'File a Refund Request',
    contactInfo: {
      description: 'help@protecht.com',
      introVerbiage:
        'That’s okay! With a little assistance from you, we should be able to find your information. If you need help, please contact ',
    },
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you consent to the processing of your personal information under our privacy policy (available below).',
    button: 'Start Refund Request',
    noOrders: 'No orders found.',
    rules: {
      row1: '1) You can select one or more issued Item ID(s) for each refund request',
      row2: '2) You can not select Item ID(s) from separate orders',
    },
    header: {
      row2: "Please select the Item ID(s) for which you'd like to start a refund.",
    },
    itemTable: {
      header: 'Refundable Amount',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  reviewPage: {
    header: {
      description:
        'Below, please review the information you provided for accuracy. Providing detailed information assists us in expediting your refund request. Once you confirm all is correct, please sign your name in the "Sign Here" box and then click "Submit Refund Request"',
    },
    sign: {
      button: 'Submit Refund Request',
      cancel: '',
      note: '',
      claims: {
        text: "By clicking 'Submit Request' you certify that all information on this request is accurate and truthful under Federal Law and under penalty of perjury. ",
        floridaText:
          'For Florida Residents: Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of refund request or an application containing any false, incomplete, or misleading information is guilty of a felony (of the third degree). ',
        footer:
          "FanShield, LLC is a licensed insurance agency NPM: 18828928 wholly owned by Protecht, Inc. Fanshield, RegShield, and TourShield are marketing product names for the ticket event insurance policy underwritten by Markel Insurance Company. Terms, Conditions, and Coverage may vary by State, Event, and other factors. See Application and Policy for full details. You can view Protecht, inc's full Privacy Policy ",
        link: {
          text: {
            row2: 'updates concerning your policy.',
          },
        },
      },
    },
    reviewTable: {
      total: 'Total Refund Amount: ',
      title: 'Description',
      covered: 'Refundable Amount',
    },
  },
  addressForm: {
    labels: {
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      title: {
        submitted: 'Thank You, Have a Great Day!',
        notSubmitted: 'Thank You for Filing Your Refund Request.',
      },
      description: {
        row2: 'refund request',
      },
    },
    description:
      'Please retain your refund request number above for future reference. You will receive a follow-up email within the next 24 hours. If you have additional questions after receiving that email, please ',
  },
  links: {
    helpEmail: 'mailto:help@protecht.com',
  },
};
