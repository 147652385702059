import { claimApi } from 'store/baseApi';
import { ProductType, ProductEnum } from 'types';

export const getPerils = (product: ProductType, policyId: string) => {
  const endPoint =
    product === ProductEnum.shopguarantee ? 'available-perils' : 'perils';
  return claimApi.get(`policies/${policyId}/${endPoint}`);
};

const api = { getPerils };

export default api;
