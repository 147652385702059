// react
import React, { VFC } from 'react';

// packages
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// hooks
import { useHistory } from 'react-router-dom';

// components
import FileSelection from 'components/FileSelection';
import PerilIcon from 'components/PerilIcon';

// utils
import { resolveObjectFromConfigToReduxState } from 'store/hooks';
import { radioGroupText } from 'utils/radioGroup';

// types
import type {
  AddressPanelConfigProps,
  ComponentProps,
  CreditCardConfigProps,
  DatePanelConfigProps,
  DecisionPanelConfigProps,
  DescriptionPanelConfigProps,
  FileUploadPanelConfigProps,
  InputPanelConfigProps,
  PerilsPanelConfigProps,
  TextAreaPanelConfigProps,
  VerbiageTypes,
} from 'types';
import type { StepComponent } from 'components/FormBuilder/types';
import type { Peril } from 'store/modules/perils/types';
import type {
  AddressPanelType,
  CreditCardType,
  DatePanelType,
  DescriptionPanelType,
  FileUploadPanelType,
  InputPanelType,
  PerilsPanelType,
  TextAreaType,
} from 'utils/translation/types';
import type { UploadedFileFormat } from 'components/UploadFiles/types';

const style = {
  peril: {
    height: '100px',
    width: '100px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centered: { display: 'flex', justifyContent: 'center' },
};

const BasePanel = function ({
  formValues,
  props,
  translation,
}: {
  formValues: Record<string, any>;
  props: DatePanelConfigProps | InputPanelConfigProps;
  translation: DatePanelType | InputPanelType;
}) {
  return (
    <Grid item {...props.reviewPageGrid}>
      {translation.reviewPageTitle && (
        <Typography fontWeight="bold" pb={1}>
          {translation.reviewPageTitle}:
        </Typography>
      )}
      <Typography>{formValues[props.name]}</Typography>
    </Grid>
  );
};

const ReviewBuilder: VFC<{
  formValues: Record<string, any>;
  props?: ComponentProps;
  type: StepComponent;
  verbiages: VerbiageTypes;
}> = function ({ formValues, props, type, verbiages }) {
  // hooks
  const history = useHistory();

  // constants
  const isRefundReviewPage =
    history.location.pathname.includes('/refund/step4');

  // functions
  const handleSplitDateTime = (dateTime: string) => {
    if (!dateTime) return [];

    const index = dateTime.indexOf(' ');

    const splits = [dateTime.slice(0, index), dateTime.slice(index + 1)];

    if (!splits.length) return [];

    return splits;
  };

  switch (type) {
    case 'InputPanel': {
      const p = props as InputPanelConfigProps;

      if (
        p.excludeFromReview ||
        (isRefundReviewPage &&
          (p.name === 'eventName' || p.name === 'location'))
      )
        return null;

      const translation = verbiages as InputPanelType;

      return (
        <BasePanel
          formValues={formValues}
          props={p}
          translation={translation}
        />
      );
    }

    case 'DatePanel': {
      const p = props as DatePanelConfigProps;

      if (p.excludeFromReview || (isRefundReviewPage && p.name === 'date'))
        return null;

      const translation = verbiages as DatePanelType;

      if (
        p.displayTime &&
        !formValues?.attendance_arrival_date &&
        !formValues?.attendance_departure_date
      )
        return null;

      if (
        p.displayTime &&
        (formValues?.attendance_arrival_date ||
          formValues?.attendance_departure_date)
      )
        return (
          <Grid item {...p.reviewPageGrid}>
            <Typography fontWeight="bold" pb={1}>
              Attendance Date and Time:
            </Typography>
            <Box pb={3}>
              <Typography fontWeight="bold" pb={1}>
                Arrival:
              </Typography>
              <div style={{ ...style.centered, display: 'flex-col' }}>
                {handleSplitDateTime(formValues.attendance_arrival_date).map(
                  (item, i) => (
                    <Box key={i}>
                      <Typography>{item}</Typography>
                    </Box>
                  )
                )}
              </div>
            </Box>
            <Box>
              <Typography fontWeight="bold" pb={1}>
                Departure:
              </Typography>
              <div style={{ ...style.centered, display: 'flex-col' }}>
                {handleSplitDateTime(formValues.attendance_departure_date).map(
                  (item, i) => (
                    <Box key={i}>
                      <Typography>{item}</Typography>
                    </Box>
                  )
                )}
              </div>
            </Box>
          </Grid>
        );

      return (
        <BasePanel
          formValues={formValues}
          props={p}
          translation={translation}
        />
      );
    }

    case 'DecisionPanel': {
      const p = props as DecisionPanelConfigProps;
      return (
        <>
          {p.childrenComponents.map((elem, index) => (
            <ReviewBuilder
              type={elem.component}
              key={`subReviewComponent${index}`}
              props={elem.props}
              verbiages={elem.verbiages}
              formValues={formValues}
            />
          ))}
        </>
      );
    }

    case 'AddressPanel': {
      const p = props as AddressPanelConfigProps;

      if (p.excludeFromReview) return null;

      const translation = verbiages as AddressPanelType;

      return (
        <Grid item {...p.reviewPageGrid}>
          {translation.reviewPageTitle && (
            <Typography fontWeight="bold" pb={1}>
              {translation.reviewPageTitle}:
            </Typography>
          )}
          <Typography>{formValues[`${p.name}_address_1`]}</Typography>
          <Typography>{formValues[`${p.name}_address_2`]}</Typography>
          <Typography>{formValues[`${p.name}_city`]}</Typography>
          <Typography>{formValues[`${p.name}_state`]}</Typography>
          <Typography>
            {formValues[`${p.name}_country`]},{' '}
            {formValues[`${p.name}_zip_code`]}
          </Typography>
          <Typography fontWeight="bold" pt={3}>
            Phone:
          </Typography>
          <Typography>{formValues[`${p.name}_phone`]}</Typography>
        </Grid>
      );
    }

    case 'UpdateBillingAddressPanel': {
      const p = props as AddressPanelConfigProps;
      if (p.excludeFromReview) return null;
      const translation = verbiages as AddressPanelType;
      return (
        <Grid item {...p.reviewPageGrid}>
          {translation.reviewPageTitle && (
            <Typography fontWeight="bold" pb={1}>
              {translation.reviewPageTitle}:
            </Typography>
          )}
          <Typography>
            First Name: {formValues[`${p.name}_first_name`]}
          </Typography>
          <Typography>
            Last Name: {formValues[`${p.name}_last_name`]}
          </Typography>
          <Typography>
            Address 1: {formValues[`${p.name}_address_1`]}
          </Typography>
          <Typography>
            Address 2: {formValues[`${p.name}_address_2`]}
          </Typography>
          <Typography>City: {formValues[`${p.name}_city`]}</Typography>
          <Typography>State: {formValues[`${p.name}_state`]}</Typography>
          <Typography>Zip Code: {formValues[`${p.name}_zip_code`]}</Typography>
          <Typography>Country: {formValues[`${p.name}_country`]}</Typography>
        </Grid>
      );
    }

    case 'DescriptionPanel': {
      const p = props as DescriptionPanelConfigProps;
      if (p.excludeFromReview) return null;
      const translation = resolveObjectFromConfigToReduxState(
        verbiages
      ) as DescriptionPanelType;
      return (
        <Grid item {...p.reviewPageGrid}>
          <Typography fontWeight="bold" textTransform="capitalize">
            {translation.reviewPageTitle}
          </Typography>
          <Typography my={3}>{translation.description}</Typography>
        </Grid>
      );
    }

    case 'FileUploadPanel': {
      const p = props as FileUploadPanelConfigProps;
      if (p.excludeFromReview) return null;
      const uploads: string[] = formValues[p.name].map(
        (fileInfo: UploadedFileFormat) => fileInfo.url
      );
      const translation = verbiages as FileUploadPanelType;
      return (
        <Grid item {...p.reviewPageGrid}>
          {translation.reviewPageTitle && (
            <Typography fontWeight="bold" pb={1}>
              {translation.reviewPageTitle}
            </Typography>
          )}
          {uploads && uploads.length > 0 ? (
            <FileSelection uploads={uploads} />
          ) : (
            <Typography> {translation.noFileUploaded} </Typography>
          )}
        </Grid>
      );
    }

    case 'PerilsPanel': {
      const p = props as PerilsPanelConfigProps;
      if (p.excludeFromReview) return null;
      const translation = verbiages as PerilsPanelType;
      const peril = formValues[p.name] as Peril;
      return (
        <>
          {translation.reviewPageTitle && (
            <Typography fontWeight="bold" pb={1}>
              {translation.reviewPageTitle}
            </Typography>
          )}
          <Grid container display="flex" justifyContent="center">
            <Grid item>
              <Card sx={{ ...style.peril, p: 3 }}>
                <PerilIcon titleAccess={peril?.name ?? ''} icon={peril.icon} />
              </Card>
            </Grid>
            <Grid item xs={8} sm={10} paddingTop={2}>
              {peril?.legal_description}
            </Grid>
          </Grid>
        </>
      );
    }

    case 'TextAreaPanel': {
      const p = props as TextAreaPanelConfigProps;
      if (p.excludeFromReview) return null;
      const translation = verbiages as TextAreaType;
      const text = formValues[p.name];
      return (
        <Grid item {...p.reviewPageGrid}>
          <Typography fontWeight="bold" textTransform="capitalize">
            {translation.reviewPageTitle}:
          </Typography>
          <Typography my={3}>{text}</Typography>
        </Grid>
      );
    }

    case 'MultiSelect': {
      const p = props as any;

      if (p.excludeFromReview) return null;
      const formKey = formValues[p.name];
      const radioGroupOptions = radioGroupText();
      const text = radioGroupOptions.find(({ key }) => formKey === key).value;
      const description = formValues[`${p.name}_description`];
      const translation = verbiages as any;
      return (
        <Grid item {...p.reviewPageGrid}>
          <Typography fontWeight="bold" textTransform="capitalize">
            {translation.reviewPageTitle}
          </Typography>
          {formKey === 'other' ? (
            <Typography my={3}>{description}</Typography>
          ) : (
            <Typography my={3}>{text}</Typography>
          )}
        </Grid>
      );
    }

    case 'CreditCardPanel': {
      const p = props as CreditCardConfigProps;
      if (p.excludeFromReview) return null;
      const translation = verbiages as CreditCardType;
      const number = formValues[`${p.name}_card_number`];
      return (
        <Grid item {...p.reviewPageGrid}>
          {translation.reviewPageTitle && (
            <Typography fontWeight="bold" pb={1}>
              {translation.reviewPageTitle}
            </Typography>
          )}

          <Typography>{`Name: ${formValues[`${p.name}_name`]}`}</Typography>
          <Typography>{`Credit Card Number: ************${number.substring(
            number.length - 4
          )}`}</Typography>
        </Grid>
      );
    }

    default:
      return null;
  }
};

export default ReviewBuilder;
