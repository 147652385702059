// packages
import { datadogRum } from '@datadog/browser-rum';

// redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorData } from 'store/errorInterceptor';
import { getProductValue } from 'store/utils/hooks';
import { selectSelectedItems } from 'store/modules/orders/selectors';
import { selectUrlProduct } from 'store/modules/config/selectors';
import api from 'store/modules/perils/api';

// types
import type { RootState } from 'store/types';
import type { ProductType } from 'types';

const noPolicyIdFoundError = 'No policy ID found for selected items';

export const getPerils = createAsyncThunk(
  'perils/getPerils',
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    try {
      const product = getProductValue(selectUrlProduct(state));
      const selectedItems = selectSelectedItems(state);
      const policyId =
        selectedItems &&
        selectedItems.length > 0 &&
        selectedItems[0]?.policy?.id
          ? selectedItems[0].policy.id
          : '';

      if (!policyId) throw noPolicyIdFoundError;

      const response = await api.getPerils(product as ProductType, policyId);
      return response.data;
    } catch (e: any) {
      if (e?.response?.data) {
        const errorData = e.response.data as ErrorData;
        const errorMessage = errorData?.detail || errorData?.error?.message;
        return rejectWithValue(errorMessage);
      }

      if (e === noPolicyIdFoundError) {
        datadogRum.addError(noPolicyIdFoundError);
        return rejectWithValue('Error policy ID not found for selected items');
      }

      return rejectWithValue('Error fetching perils');
    }
  }
);
